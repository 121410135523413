export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.scrollup').fadeIn();
      } else {
        $('.scrollup').fadeOut();
      }
    });
    
    $('.scrollup').click(function () {
      $('html, body').animate({
        scrollTop: 0,
      }, 600);
      return false;
    });

    // toggles hamburger and nav open and closed states
    var removeClass = true;
    $('.hamburger').click(function () {
      $('.hamburger').toggleClass('is-active');
      $('#sideNav').toggleClass('sideNav-open');
      $('.sideNavBody').toggleClass('sideNavBody-push');
      removeClass = false;
    });

    $('.sideNav').click(function() {
      removeClass = false;
    });

    document.addEventListener('touchstart', function(e) {
      if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
        $('.hamburger').removeClass('is-active');
        $('#sideNav').removeClass('sideNav-open');
        $('.sideNavBody').removeClass('sideNavBody-push');
      }
      removeClass = true;
    }, false);

        //dropdown on click
        $('.banner .nav li.menu-item-has-children > a').click(function(e) {
          console.log('drop click');
          e.preventDefault();
          e.stopPropagation();
          $(this).parent('.banner .nav li.menu-item-has-children').toggleClass('open');
          $(this).parent('.banner .nav li.menu-item-has-children').siblings('.open').removeClass('open');
        });
    
        //close dropdown when clicking anywhere else
        $(document).on('click touch', function(e) {
          if( !$(e.target).hasClass('.banner .nav li.menu-item-has-children') && !$(e.target).parents('.banner .nav li.menu-item-has-children').length ) {
            $('.banner .nav li.menu-item-has-children.open').removeClass('open');
          }
        });
    
        //sidenav dropdown on click
        $('.sideNav li.menu-item-has-children > a').click(function(e){
          e.preventDefault();
          $(this).next('.sideNav .sub-menu').slideToggle();
          $(this).parent('.sideNav li.menu-item-has-children').toggleClass('open');
        });

      //review us page
      $('a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
        localStorage.setItem('activeTab', $(e.target).attr('href'));
        });
        var activeTab = localStorage.getItem('activeTab');
        if (activeTab) {
        $('#review-tab a[href="' + activeTab + '"]').tab('show');
        }

        //Testimonial slider
    if ($('.testimonials-carousel').length) {
      $('.testimonials-carousel').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        appendArrows: '#testimonials-panel .slider-nav',
        appendDots: '#testimonials-panel .slider-nav',
        arrows: true,
        dots: true,
        speed: 1300,
        responsive: [
          {
            breakpoint: 1599,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }

        //Testimonial slider - Advertiser
        if ($('#testimonials-panel-adv .testimonials-carousel').length) {
          $('#testimonials-panel-adv .testimonials-carousel').slick({
            slidesToShow: 4,
            slidesToScroll: 2,
            appendArrows: '#testimonials-panel-adv .slider-nav',
            appendDots: '#testimonials-panel-adv .slider-nav',
            arrows: true,
            dots: true,
            speed: 1300,
            responsive: [
              {
                breakpoint: 1599,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: false,
                },
              },
            ],
          });
        }

    //add dynamic More link to testimonial text
    var testimonialClass = 'testimonial-quote p'; //class that wraps testimonial text in html

    $('.' + testimonialClass).each(function() {
      var maxLength = 240;
      var text = $(this).text();
      if(text.length > maxLength) {
        var shortText = text.substr(0, maxLength);
        var longText = text.substr(maxLength);
        var html = shortText + '<a href="#" class="read-more">... More</a><span class="more-text">' + longText + '</span>';
        $(this).html(html);
        $(this).find('.more-text').hide();
      }
    });
      
    $('.slick-slide .read-more').click(function(e) {
      e.preventDefault();
      $(this).hide();
      $(this).siblings('.more-text').show();
      $(this).closest('.slick-slide').siblings().find('.more-text').hide();
      $(this).closest('.slick-slide').siblings().find('.read-more').show();
    });
      
    $('.slick-arrow, .slick-dots button').click(function(e) {
      e.preventDefault();
      $('.' + testimonialClass + ' .more-text').hide();
      $('.' + testimonialClass + ' .read-more').show();
    });

  },
};
